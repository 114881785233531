import { PaymentItem } from 'types/payment'
import { Project } from 'types/project'

export const FORM_CABINETRY_DISCOUNT_OPTIONS = [
  { key: '0', value: '0', text: '0% - no discount' },
  { key: '5', value: '5', text: '5% - referral discount', disabled: true },
  { key: '10', value: '10', text: '10% - factory incentive' },
  { key: '15', value: '15', text: '15% - trade incentive' },
  { key: '25', value: '25', text: '25% - partner incentive' },
  { key: '40', value: '40', text: '40% - At-cost pricing' },
]

// % off cabinetry
export const getCabinetryDiscountText = (discount: number) => {
  if (!discount) return ''
  let discountText = ''
  switch (discount) {
    case 0:
      discountText = ''
      break
    case 5:
      discountText = `<span>5% referral discount</span> applied to your project</strong>`
      break
    case 10:
      discountText =
        '<span>10% Factory incentive</span> applied to your project'
      break
    case 15:
      discountText = '<span>15% Trade discount</span> applied to your project'
      break
    case 25:
      discountText = '<span>25% Partner discount</span> applied to your project'
      break
    case 40:
      discountText = '<span>40% At-cost pricing</span> applied to your project'
      break
    default:
      discountText = `${discount}% price adjustment</span> applied to your project `
      break
  }

  return discountText
}

// $ off - affiliates
export const getProjectDiscountText = (
  affiliateCode: string,
  projectDiscount: number,
) => {
  const affiliate = AffiliateCodes.find((ac) => ac.code === affiliateCode)
  if (affiliate)
    return `<strong>${affiliate.code} - ${affiliate.amount} ${affiliate.description}</strong>`
  return `<strong>Referral discount - $${projectDiscount} off your kitchen</strong>`
}

export type AffiliateData = {
  active: boolean
  amount: string
  baseCabinetryDiscount: number
  code: string
  description: string
  name?: string
  shortName?: string
  stripeAccount?: string
  projectDiscount: number
  introVideoUrl?: string
}

export const AffiliateCodes: AffiliateData[] = [
  {
    active: true,
    code: 'AJJ-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'BACKDROP-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'BARCCI-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1Q3nrg2XIBRwa5iD',
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'BF-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'BLUE-MOUNTAIN-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1QFQUmFTmhveJn8W',
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'CAESARSTONE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'CRAIG-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1QFQKp2Yr9RBgYgS',
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'DARBY-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'DEE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'DEZIGNBLUE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'FIRECLAY-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'GORTIN-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1QFQQLFbNuSPzVNk',
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'HEREABOUT-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'HH-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'HTH-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'JEN-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'JENNAIR-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'labor-day-special',
    amount: '$1000',
    description: 'off your kitchen',
    baseCabinetryDiscount: 0,
    projectDiscount: 1000,
  },
  {
    active: true,
    code: 'LANCE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    shortName: 'Lance',
    name: 'Lance Pendleton',
    stripeAccount: 'acct_1QL4YzFPW945AC3Z',
    projectDiscount: 0,
    introVideoUrl: `/videos/affiliates/lance-form.mp4`,
  },
  {
    active: true,
    code: 'MAMJ-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'MAYES-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'MOLLY-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    shortName: 'Molly',
    name: 'Molly Beard',
    stripeAccount: 'acct_1QBOdKFJaDJ9X8Xt',
    projectDiscount: 0,
    introVideoUrl: `/videos/affiliates/molly-form.mp4`,
  },
  {
    active: true,
    code: 'NADYA-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'NEXT-DB-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'NK-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'PEREZ-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1Q9zgI2ZgqgD3hBM',
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'PHIL-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'ROMERO-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'SCHOOLHOUSE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'SMV-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    stripeAccount: 'acct_1Q9zYzFR2NbgndeA',
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'SKOOLIE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'STUGA-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: false,
    code: 'summer-special',
    amount: '$1000',
    description: 'off your kitchen',
    baseCabinetryDiscount: 0,
    projectDiscount: 1000,
  },
  {
    active: true,
    code: 'VINCE-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    shortName: 'Vince',
    name: 'VinceTheKitchenGuy',
    stripeAccount: 'acct_1Q0rpCFVKaUd8OXf',
    projectDiscount: 0,
    introVideoUrl: `/videos/affiliates/vince-form.mp4`,
  },
  {
    active: true,
    code: 'WATKINS-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
  {
    active: true,
    code: 'WB-FORM',
    amount: '',
    description: '',
    baseCabinetryDiscount: 0,
    projectDiscount: 0,
  },
]

export const validateAffiliateCode = (affiliateCode: string) => {
  const validatedAffiliateCode = AffiliateCodes.find(
    (ac) => ac.active && ac.code === affiliateCode,
  )
  if (validatedAffiliateCode) {
    return {
      affiliateCode: validatedAffiliateCode.code,
      name: validatedAffiliateCode.name || '',
      introVideoUrl: validatedAffiliateCode.introVideoUrl || '',
      shortName: validatedAffiliateCode.shortName || '',
    }
  }
  return null
}

export const getDiscountText = (project?: Project) => {
  if (!project) return ''
  if (project.data?.baseCabinetryDiscount)
    return getCabinetryDiscountText(project.data.baseCabinetryDiscount)
  if (project.data?.projectDiscount)
    return getProjectDiscountText(
      project.data.affiliateCode ?? '',
      project.data.projectDiscount,
    )
  return ''
}

export const getProjectDiscounts = (project?: Project): PaymentItem[] => {
  if (!project) return [] as PaymentItem[]
  if (project.data?.baseCabinetryDiscount) return [] as PaymentItem[]
  if (!project.data?.projectDiscount) return [] as PaymentItem[]
  return [
    {
      name: `${project.data?.affiliateCode || 'Affiliate'} discount`,
      price: -project.data.projectDiscount * 100,
      quantity: 1,
    },
  ]
}
